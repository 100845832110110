<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Stock In Out -->
        <b-form-group
          id="input-group-stock-in-out"
          label="Jenis Stok:"
          label-for="input-stock-in-out"
        >
          <b-form-select
            v-model="form.stock_in_out"
            :options="options"
            @input="setFormStockInOut"
          ></b-form-select>
          <small class="text-danger">{{ error.stock_in_out }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-trigger-modal-inventory"
          label="Inventori:"
          label-for="input-trigger-modal-inventory"
        >
          <b-form-input
            id="input-trigger-modal-inventory"
            v-model="display.inventory_name"
            placeholder="Pilih Inventori"
            readonly
            @click="$bvModal.show('modal-inventory')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.inventory_id }}</small>
        </b-form-group>

        <!-- Input Quantity -->
        <b-form-group
          id="input-group-quantity"
          label="Kuantitas:"
          label-for="input-quantity"
        >
          <b-form-input
            id="input-quantity"
            v-model="form.quantity"
            placeholder="Kuantitas"
          >
          </b-form-input>
          <small class="text-danger">{{ error.quantity }}</small>
        </b-form-group>

        <!-- Input Reference No -->
        <!-- <b-form-group id="input-group-ref-no" v-if="stock_in">
          <label for="input-ref-no"
            >Reference No: <em class="text-muted">opsional</em></label
          >
          <b-form-input
            id="input-ref-no"
            v-model="form.ref_no"
            placeholder="Reference No"
          >
          </b-form-input>
          <small class="text-danger">{{ error.ref_no }}</small>
        </b-form-group> -->

        <!-- Input Vendor -->
        <b-form-group id="input-group-vendor" v-if="stock_in">
          <label for="input-vendor"
            >Supplier: <em class="text-muted">opsional</em></label
          >
          <treeselect
            v-model="form.vendor_id"
            :multiple="false"
            :options="vendors"
          />
          <small class="text-danger">{{ error.vendor_id }}</small>
        </b-form-group>

        <!-- Input Warehouse -->
        <b-form-group id="input-group-warehouse" v-if="stock_in">
          <label for="input-warehouse"
            >Gudang: </label
          >
          <treeselect
            v-model="form.warehouse_id"
            :multiple="false"
            :options="warehouse"
          />
          <small class="text-danger">{{ error.warehouse_id }}</small>
        </b-form-group>

        <!-- Input Description -->
        <b-form-group id="input-group-notes">
          <label for="input-notes"
            >Catatan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            placeholder="Catatan"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/inventory/maintain-stock')"
        >
          Batal
        </b-button>
      </b-form>
      <ModalInventory @chosenItem="chosenItem" />
    </v-app>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalInventory from "@/component/general/ModalInventory.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add"
    },
  },

  components: {
    Card,
    ModalInventory,
  },

  data() {
    return {
      // Display Data
      display: {
        inventory_name: "",
      },
      medicine: [],
      vendors: [],
      warehouse: [],
      rack: [],
      modal2: false,
      stock_in: true,
      // Error
      error: {
        inventory_id: "",
        inventory_name: "",
        stock_in_out: "",
        quantity: "",
        remaining_stock: "",
        price: "",
        vendor_id: "",
        vendor_name: "",
        notes: "",
        ref_no: "",
      },
      options: [
        { value: null, text: "Pilih Jenis Stok", disabled: true },
        { value: 0, text: "Stok Masuk" },
        { value: 1, text: "Stok Keluar" },
      ],
      route_vendor: "",
      form_vendor: {
        inventory_id: this.form.inventory_id,
        vendor_id: this.form.vendor_id,
      },
      quantity_count: 0,
      warehouseChoosed: false,
    };
  },

  computed: {
    formatedHna: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.hna = newValue.toLocaleString("id-ID");
        } else {
          this.form.hna = "";
        }
      },
      get() {
        return this.form.hna;
      },
    },
  },

  methods: {
    chosenItem(value) {
      this.form.inventory_id = value.id;
      this.display.inventory_name = value.name;
      this.$bvModal.hide("modal-inventory");
    },

    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
      }
    },
    async getWarehouseOption() {
      let response = await module.setTreeSelect("warehouses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.warehouse = response.data;
      }
    },
    async getRackOption(id) {
      let response = await module.setTreeSelect("warehouses-rack/" + id);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.rack = response.data;
        this.warehouseChoosed = true;
      }
    },

    setFormStockInOut() {
      console.log("set form");
      if (this.stock_in == true) {
        this.stock_in = false;
      } else if (this.stock_in == false) {
        this.stock_in = true;
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/inventory/maintain-stock");
      }
    },
  },

  mounted() {
    this.getVendorOption();
    this.getWarehouseOption();
  },
  watch: {
    "form.inventory_name": function (newVal, oldVal) {
      this.display.inventory_name = newVal;
    },
  },
};
</script>

<style>
</style>